var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('div',{staticClass:"d-flex mb-2 align-center"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search by email or name or id","single-line":"","hide-details":"","outlined":"","dense":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.handleSearch}},[_vm._v(" Search ")])],1),_c('div',{staticClass:"table-container text-body-2"},[_c('v-data-table',{staticClass:"elevation-0 text-body-2",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.users && _vm.users.data || [],"server-items-length":_vm.meta.total || 0,"items-per-page":_vm.meta.per_page && parseInt(_vm.meta.per_page) || 15,"page":_vm.meta.current_page || 1,"footer-props":{
                itemsPerPageOptions: [15, 20, 30], 
                showFirstLastPage: true
            }},on:{"update:page":function($event){return _vm.$set(_vm.meta, "current_page || 1", $event)},"click:row":_vm.handleClickRow,"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.current_period_end",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.current_period_end))+" ")]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.created_at))+" ")]}},{key:"item.account_type",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getAccountTypeColor(item.account_type)}},[_vm._v(_vm._s(item.account_type))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }