<template>
    <admin-dashboard-layout>
        <div class="d-flex mb-2 align-center">
            <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search by email or name or id"
                single-line
                hide-details
                class="mr-2"
                outlined
                dense
                clearable
                v-on:keydown.enter="handleSearch"
            ></v-text-field>
            <v-btn @click="handleSearch" color="primary" elevation="0" >
                Search
            </v-btn>
        </div>

        <div class="table-container text-body-2">
            <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="users && users.data || []"
                :server-items-length="meta.total || 0"
                :items-per-page="meta.per_page && parseInt(meta.per_page) || 15"
                :page.sync="meta.current_page || 1"
                :footer-props="{
                    itemsPerPageOptions: [15, 20, 30], 
                    showFirstLastPage: true
                }"
                class="elevation-0 text-body-2"
                @click:row="handleClickRow"
                @pagination="onPagination"
            >
                <template v-slot:item.current_period_end="{ item }">
                    {{ $date(item.current_period_end) }} <!--TODO add day.js and default format -->
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ $date(item.created_at) }} <!--TODO add day.js and default format -->
                </template>
                <template v-slot:item.account_type="{ item }">
                    <v-chip small :color="getAccountTypeColor(item.account_type)">{{item.account_type}}</v-chip>
                </template>
            </v-data-table>
        </div>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import AdminApi from "@/api/AdminApi";

    export default {
        name: "users-index",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                loading: false,
                fetchRes: null,
                users: {},
                search: '',
                loaded: false,
                // fetchedUsers: null,
                headers: [
                    { text: 'Email', value: 'email', align: 'start', },
                    { text: 'Name', value: 'name', align: 'start', },
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    // { text: 'Submissions', value: 'total_submissions' },
                    // { text: 'Transcripts', value: 'total_transcripts' },
                    { text: 'Created at', value: 'created_at' },
                    // { text: 'Monthly cycle ends at', value: 'current_period_end' },
                    { text: 'Account Type', value: 'account_type' },
                ],
            }
        },
        computed: {
            meta(){
                return this.users && this.users.meta || {}
            },
        },
        watch: {
            '$route.query': {
                immediate: true,
                handler() {
                    const page = parseInt(this.$route.query.page) || 1;
                    const itemsPerPage = parseInt(this.$route.query.itemsPerPage) || 15;
                    this.search = this.$route.query.search || '';
                    this.fetchUsers(page, itemsPerPage, this.search);
                }
            }
        },
        methods: {
            handleClickRow(user) {
                this.$router.push({name: 'admin.users.show', params: {id: user.id}})
            },
            handleSearch(){
                // Update the URL with the search query
                this.$router.push({
                    name: this.$route.name,
                    query: { 
                        ...this.$route.query,
                        search: this.search
                    }
                });
            },
            getAccountTypeColor(type){
                switch (type) {
                    case 'free':
                        return 'default' 
                    case 'standard':
                        return 'success' 
                    case 'app-sumo':
                        return 'warning' 
                    default:
                        return 'primary';
                }
            },
            async fetchUsers(page, itemsPerPage, search){
                this.loading = true
                this.fetchRes = await AdminApi.getUsers(page, itemsPerPage, search);
                if(this.fetchRes && this.fetchRes.data){
                    this.users = {...this.fetchRes.data}
                }
                this.loading = false
            },
            async onPagination(pagination){
                if(this.loaded && (pagination.page !== this.users.meta.current_page || pagination.itemsPerPage !== this.users.meta.per_page)){
                    this.$router.push({
                        name: this.$route.name,
                        query: { 
                            ...this.$route.query,
                            page: pagination.page, 
                            itemsPerPage: parseInt(pagination.itemsPerPage)
                        }
                    });
                }else{
                    this.loaded = true
                }
            }
        },
    }
</script>

<style type="scss" scoped>
 .table-container {
     overflow-x: auto;
 }
</style>
